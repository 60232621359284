<template>
  <div v-cloak>
    <transition name="fade" appear appear-active-class="fade-enter-active">
      <div class="wrap">


         <router-link class="goBack" to="/">
          <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.5575 8.435L2.1225 5L5.5575 1.5575L4.5 0.5L-4.76837e-06 5L4.5 9.5L5.5575 8.435Z" fill="#0033A0"/>
          </svg>
          назад
        </router-link>

        <h1>Справочники</h1>


        <div class="directories">

            <div>
              <div class="icon">
                <svg width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.68 12.19L8 10.43L10.31 12.19L9.43 9.34L11.75 7.5H8.91L8 4.69L7.09 7.5H4.25L6.56 9.34L5.68 12.19ZM16 8.5C16 4.08 12.42 0.5 8 0.5C3.58 0.5 0 4.08 0 8.5C0 10.53 0.76 12.37 2 13.78V21.5L8 19.5L14 21.5V13.78C15.24 12.37 16 10.53 16 8.5ZM8 2.5C11.31 2.5 14 5.19 14 8.5C14 11.81 11.31 14.5 8 14.5C4.69 14.5 2 11.81 2 8.5C2 5.19 4.69 2.5 8 2.5ZM8 17.5L4 18.52V15.42C5.18 16.1 6.54 16.5 8 16.5C9.46 16.5 10.82 16.1 12 15.42V18.52L8 17.5Z" fill="#69B3E7"/>
                </svg>
              </div>
              <h2>Справочник профессий (специальностей)</h2>
              <router-link link to="/Directory1">ПЕРЕЙТИ 
                <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.589996 10.58L5.17 6L0.589996 1.41L2 0L8 6L2 12L0.589996 10.58Z" fill="#0033A0"/>
                </svg>
              </router-link>
            </div>
            <div>
              <div class="icon">
               <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11 0L0 6L4 8.18V14.18L11 18L18 14.18V8.18L20 7.09V14H22V6L11 0ZM17.82 6L11 9.72L4.18 6L11 2.28L17.82 6ZM16 12.99L11 15.72L6 12.99V9.27L11 12L16 9.27V12.99Z" fill="#69B3E7"/>
              </svg>
              </div>
              <h2>Справочник компетенций</h2>
              <router-link link to="/Directory2">ПЕРЕЙТИ 
                <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.589996 10.58L5.17 6L0.589996 1.41L2 0L8 6L2 12L0.589996 10.58Z" fill="#0033A0"/>
                </svg>
              </router-link>
            </div>
            <div class="v-card--disabled">
              <div class="icon">
                <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 7C8.93 7 10.5 5.43 10.5 3.5C10.5 1.57 8.93 0 7 0C5.07 0 3.5 1.57 3.5 3.5C3.5 5.43 5.07 7 7 7ZM7 2C7.83 2 8.5 2.67 8.5 3.5C8.5 4.33 7.83 5 7 5C6.17 5 5.5 4.33 5.5 3.5C5.5 2.67 6.17 2 7 2ZM7.05 12H2.77C3.76 11.5 5.47 11 7 11C7.11 11 7.23 11.01 7.34 11.01C7.68 10.28 8.27 9.68 8.98 9.2C8.25 9.07 7.56 9 7 9C4.66 9 0 10.17 0 12.5V14H7V12.5C7 12.33 7.02 12.16 7.05 12ZM14.5 9.5C12.66 9.5 9 10.51 9 12.5V14H20V12.5C20 10.51 16.34 9.5 14.5 9.5ZM15.71 7.68C16.47 7.25 17 6.44 17 5.5C17 4.12 15.88 3 14.5 3C13.12 3 12 4.12 12 5.5C12 6.44 12.53 7.25 13.29 7.68C13.65 7.88 14.06 8 14.5 8C14.94 8 15.35 7.88 15.71 7.68Z" fill="#69B3E7"/>
                </svg>
              </div>
              <h2>Пользователи</h2>
              <router-link link to="/">ПЕРЕЙТИ 
                <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.589996 10.58L5.17 6L0.589996 1.41L2 0L8 6L2 12L0.589996 10.58Z" fill="#0033A0"/>
                </svg>
              </router-link>
            </div>
          </div>
         



      </div>
    </transition>
  </div>
</template>

<script>


export default {
  name: 'Directories',

  data() {
    return {

    };
  
	}
}
</script>

<style lang="scss">
@import "../styles/main.scss";
</style>


<style scoped lang="scss">
</style>
